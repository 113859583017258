import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const agentStatsSchema = z.object({
  id: z.string(),
  name: z.string(),
  qualifications: z.array(z.string()),
  averageRating: z.number(),
  ratingsCount: z.number(),
  completedShiftsCount: z.number(),
  shiftAttendanceRate: z.number(),
});

export type AgentStats = z.infer<typeof agentStatsSchema>;

export function getAgentStatsPath(agentId: string) {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/workplace/workers-directory/${agentId}`;
}

export function useWorkerStats(
  params: { agentId: string },
  options: UseGetQueryOptions<AgentStats> = {}
): UseQueryResult<AgentStats> {
  const { agentId } = params;
  const agentStatsUrl = getAgentStatsPath(agentId);

  return useGetQuery({
    url: agentStatsUrl,
    responseSchema: agentStatsSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_STATS_FAILURE,
    },
    ...options,
  });
}
